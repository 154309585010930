import React from 'react'
import "./OrderCard.css"

const OrderCard = ({ title, desc, price, currency, variant }) => {


  return (
    <div className='order-card-container'>
        <div className='order-card-img'>

        </div>
        <div className='order-card-content'>
            <div className='order-card-title-container'>
                <div>
                    <div className='order-card-title'>{title}</div>
                    <div className='order-card-desc'>{desc}</div>
                </div>
                <div className='order-card-price-container'>
                    <div className='order-card-price'>{price} <span>{currency}</span></div>
                    <div className='order-card-price-desc'>This price without TAX and Develery</div>
                </div>
            </div>
            <div className='order-card-brand'>
                Brand: <span>Genius Kids</span>
            </div>
            
            {
                variant && (
                    <>
                        <div className='order-card-title-details'>Item details</div>
                        <div className='order-card-details-container'>
                            <div className='order-card-detail-container'>
                                <div className='order-card-spec'>Size</div>
                                <div className='order-card-detail'>85 x 55 mm</div>
                            </div>
                            <div className='order-card-detail-container'>
                                <div className='order-card-spec'>Color</div>
                                <div className='order-card-detail'>Brown</div>
                            </div>
                            <div className='order-card-detail-container'>
                                <div className='order-card-spec'>Delivery </div>
                                <div className='order-card-detail'>3 working days</div>
                            </div>
                        </div>
                    </>
                ) 
            }
           
        </div>
    </div>
  )
}

export default OrderCard