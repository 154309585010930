import React , {useState, useEffect} from 'react';
import "./SpecialOffer.css"
import { Timer } from '../../../../components';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../../../../components';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import axios from 'axios';

const SpecialOffer = ({  itemList, url, apiMethod,section }) => {
  const offer_time_title =section?.data?.offer_time_title;
  const offer_time =section?.data?.offer_time;

  const description =section?.data?.section_description;
    // const products = itemList || [];
    const [timerComplete, setTimerComplete] = useState(false); // State variable to track timer completion
    const handleTimerComplete = () => {
      setTimerComplete(true); // Update state variable when the timer is complete
    };

    const [productsData, setProductsData] = useState([]);

    useEffect(() => {
        fetchDataByPage();
    }, []);




    const fetchDataByPage = async () => {
      const apiURL = `${url}${apiMethod}.content_by_page_name?name=products&start=0&limit=10`;
      try {
        const response = await axios.get(apiURL);
        const data = response?.data?.result?.items?.items;
        setProductsData(data);
      } catch (error) {
        console.log('Error fetching products:', error);
      }
    };
 
    return (
      <Container className={`offers ${timerComplete ? 'd-none' : ''}`}>
        <Row>
          <Col lg={5} md={6} className="offer-content">

              <div className="offer-para" data-aos="fade-right" data-aos-duration="1500">
                {/* <h1>Special Offers! Up to <span>50%</span> OFF</h1>
                <p>Hurry Up! Offer End In:</p> */}
              
                <p dangerouslySetInnerHTML={{ __html: description}}/>
                <p className='offer-time-title' dangerouslySetInnerHTML={{ __html: offer_time_title}}/>

              </div>
              <Timer time={offer_time}  onComplete={handleTimerComplete}/> 
          </Col>
          
          <Col lg={7} md={6}>
            <Splide className="offer-slider"
              options= {{
                type   : 'loop',
                perPage: 3,
                perMove: 1,
                pagination: false,
                breakpoints: {
                  1200: {
                    perPage: 2,
                  },
                  992: {
                    perPage: 1,
                    gap: "0",
                    focus: 'center',
                  },
                  768: {
                    perPage: 2,
                    focus: 'none'
                  },
                  576: {
                    perPage: 1,
                    gap: "0",
                    focus: 'center',
                  }
                }
              }}
            >

                  {Array.isArray(productsData) && productsData.length !== 0 && productsData.map((product, index) => (
                    <SplideSlide>
                      <Card 
                        title={product.item_group}
                        para={product.web_item_name}
                        image={
                          product.image
                          ? url + product.image
                          : require("../../../../asset/images/Products/product1.png")
                      }
                        pricebefore={product.before_discount_price_list_rate}
                        priceafter={product.discounted_price_list_rate}
                        sale={"-25%"}
                      />
                    </SplideSlide>
                  ))}
            </Splide>
          </Col>
        </Row>
      </Container>
 
    );
  };
  
  export default SpecialOffer;