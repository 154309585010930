import React, { useState, useEffect } from 'react'
import './AboutUs.css'
import { Header } from '../../components'
import { Container, Col, Row } from 'react-bootstrap'

const AboutUs = () => {

    const [isSmallScreen, setSmallScreen] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setSmallScreen(window.innerWidth < 992);
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
        <div>
            <Header 
                title={"Home / "}
                bold={"About Us"}
                desc={"About us"}
                image={require("../../asset/images/about us/header.png")}
            />

            <Container>
                <div style={{ marginTop: "40px"}}>
                    <div className='aboutus-title'>
                        Who is a Genius Kids?
                    </div>
                    <div className='aboutus-para'>
                        A Genius Kids is a leading company in the world of children, specialized in providing interior design services for schools & nursery, equipping them with the latest equipment & furniture
                        In addition to design visual & 3D identities for projects with multiple branches whether they are schools, nursery or children's entertainment center.
                    </div>
                    <div className='top-about-image'></div>
                </div>

                <Row className='mt-5'>
                    <Col lg={6} style={{ paddingLeft: `${!isSmallScreen ? 'unset !important' : ''}` }}>
                        <div className='aboutus-title'>
                            Why Genius Kids?
                        </div>
                        <ul className='about-list'>
                            <li className='aboutus-desc'>A professional team specialized in the field of interior design</li>
                            <li className='aboutus-desc'>A work team committed to providing innovative, creative designs that meet customers’ needs and aspirations within the specified deadlines and with the highest quality standards</li>
                            <li className='aboutus-desc'>More than <span>14 years</span> of experience and expertise in providing services to educational and entertainment institutions in the Kingdom of Saudi Arabia and the Arabian Gulf</li>
                        </ul>
                        <div className='aboutus-title mt-4'>
                            Genius Kids Message
                        </div>
                        <div className='aboutus-desc mt-2'>
                            To be success partners for entrepreneurs by creating ideas that create an integrated environment specifically designed to simulate the child’s world, and equipped to meet his passion and love for exploration and develop his mental and motor abilities
                        </div>
                    </Col>
                    <Col lg={6} style={{ paddingRight: `${!isSmallScreen ? 'unset !important' : ''}`, display: 'flex', justifyContent: `${!isSmallScreen ? 'end' : ''}` }}> 
                        <div className='about-right-img'></div>
                    </Col>
                </Row>

                <Row className='mt-5' style={{ flexDirection: `${ isSmallScreen ? 'column-reverse' : ''}`}}>
                    <Col lg={6} style={{ paddingLeft: `${!isSmallScreen ? 'unset !important' : ''}`}}> 
                        <div className='about-left-img'></div>
                    </Col>
                    <Col lg={6} style={{ paddingRight: `${!isSmallScreen ? 'unset !important' : ''}`, display: 'flex', justifyContent: `${!isSmallScreen ? 'end' : ''}` }}>
                        <div style={{ width: `${!isSmallScreen ? '90%' : ''}`}}>
                            <div className='aboutus-title' style={{ display: 'flex', alignItems: 'center', gap: '5px'}}>
                                <div>Genius Kids Vision</div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path d="M2.604 8.52813C2.71234 6.34063 3.0363 4.97605 4.00713 4.0073C4.97588 3.03646 6.34046 2.71251 8.52796 2.60417M22.3957 8.52813C22.2873 6.34063 21.9634 4.97605 20.9925 4.0073C20.0238 3.03646 18.6592 2.71251 16.4717 2.60417M16.4717 22.3958C18.6592 22.2875 20.0238 21.9635 20.9925 20.9927C21.9634 20.024 22.2873 18.6594 22.3957 16.4719M8.52796 22.3958C6.34046 22.2875 4.97588 21.9635 4.00713 20.9927C3.0363 20.024 2.71234 18.6594 2.604 16.4719M20.453 11.7896C20.7061 12.1063 20.8332 12.2656 20.8332 12.5C20.8332 12.7344 20.7061 12.8938 20.453 13.2104C19.3144 14.6354 16.4071 17.7083 12.4998 17.7083C8.59255 17.7083 5.68525 14.6354 4.54671 13.2104C4.29359 12.8938 4.1665 12.7344 4.1665 12.5C4.1665 12.2656 4.29359 12.1063 4.54671 11.7896C5.68525 10.3646 8.59255 7.29167 12.4998 7.29167C16.4071 7.29167 19.3144 10.3646 20.453 11.7896Z" stroke="#CF9F47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M14.5832 12.5C14.5832 11.9475 14.3637 11.4176 13.973 11.0269C13.5823 10.6362 13.0524 10.4167 12.4998 10.4167C11.9473 10.4167 11.4174 10.6362 11.0267 11.0269C10.636 11.4176 10.4165 11.9475 10.4165 12.5C10.4165 13.0525 10.636 13.5824 11.0267 13.9731C11.4174 14.3638 11.9473 14.5833 12.4998 14.5833C13.0524 14.5833 13.5823 14.3638 13.973 13.9731C14.3637 13.5824 14.5832 13.0525 14.5832 12.5Z" stroke="#CF9F47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div className='aboutus-desc'>
                                We work to be the world's leading company in the world of children, and we aspire to be innovative in providing solutions to make the world a home for creativity and talent discovery
                            </div>
                            <div className='aboutus-title mt-4'>
                                Genius Kids Message
                            </div>
                            <div className='aboutus-desc'>
                                We work to be the world's leading company in the world of children, and we aspire to be innovative in providing solutions to make the world a home for creativity and talent discovery                        
                            </div>
                            <div className='about-genius'>
                                <h1 className='about-genius-title'>Genius Kids</h1>
                                <p className='about-genius-para'>The first step into a child's world</p>
                            </div>
                        </div>
                        
                    </Col>
                   
                </Row>
               

            </Container>
        </div>
    )
}

export default AboutUs
