import React from 'react'
import "./Title.css"

const Title = ({ title, para }) => {
    return (
        <div className="title">
            <h1>{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: para }} />
        </div>
    )
}

export default Title
