import React, { useState, useEffect } from 'react';
import "./OrderHistory.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { OrderDetails } from '../../index';
import axios from "axios";
import Cookies from "js-cookie";

const OrderHistory = ({ url, setLoadingProfile }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [filters, setFilters] = useState({
    address: '',
    name: '',
    creation: '',
    grand_total: '',
    status: ''
  });


  const [ordersData, setOrdersData] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const headers = {
            "Authorization": `token ${Cookies.get("token")}`
        };
        setLoadingProfile(true);
        const response = await axios.get(`${url}/api/method/geniusapi.www.api.orders`, 
          {headers}
        );
        setOrdersData(response.data.result);
        if(response) {
          setLoadingProfile(false)
        }
      } catch (err) {
        console.log(err.message);
        setLoadingProfile(false);
      }
    };

    fetchOrders();
  }, []);

 

  const filteredOrders = ordersData.filter(order =>
    (filters.address === '' || order.address === filters.address) &&
    (filters.name === '' || order.name === filters.name) &&
    (filters.creation === '' || order.creation === filters.creation) &&
    (filters.grand_total === '' || order.grand_total === filters.grand_total) &&
    (filters.status === '' || order.status === filters.status)
  );


  const handleFilterChange = (column, value) => {
    setFilters({ ...filters, [column]: value });
  };

  
  const getUniqueValues = (column) => {
    return [...new Set(ordersData.map(order => {
      if (column === 'date') return order.creation.split(" ")[0];
      if (column === 'cost') return Number(order.grand_total).toFixed(2); 
      if (column === 'id') return order.name; 
      return order[column];
    }))];
  };

  return (
    <div className='order-history-container'>
      {selectedOrder ? (
        <OrderDetails order={selectedOrder} onClose={() => setSelectedOrder(null)} />
      ) : (
        <>
          <div className='order-history-title'>Orders History</div>
          <div className='order-history-para'>You can show the last orders</div>
          <div className='order-table'>
            <TableContainer component={Paper} sx={{ boxShadow: 'unset', borderRadius: 'unset', borderTop: '1px solid #F4F4F4' }}>
              <Table aria-label="order history table">
                <TableHead>
                  <TableRow>
                    {['address', 'id', 'date', 'cost', 'status'].map(column => (
                      <TableCell key={column} className='order-table-title' align="left">
                        <select
                          className="order-filter-dropdown"
                          value={filters[column]}
                          onChange={(e) => handleFilterChange(column, e.target.value)}
                        >
                          <option value="">All {column.charAt(0).toUpperCase() + column.slice(1)}</option>
                          {getUniqueValues(column).map(value => (
                            <option className='order-filter-option' key={value} value={value}>{value}</option>
                          ))}
                        </select>
                      </TableCell>
                    ))}
                    <TableCell className='order-table-title' align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredOrders.map((order) => (
                    <TableRow key={order.name}>
                      <TableCell className='order-table-desc' align="left">{order.address ? order.address : ""}</TableCell>
                      <TableCell className='order-table-desc' align="left">{order.name}</TableCell>
                      <TableCell className='order-table-desc' align="left">{order.creation}</TableCell>
                      <TableCell className='order-table-desc' align="center">{order.currency + " " + order.grand_total}</TableCell>
                      <TableCell className="order-table-desc" align="center">
                        <div className={`order-status ${order.status.toLowerCase()}`}>{order.status}</div>
                      </TableCell>
                      <TableCell className='order-table-desc' align="center">
                        <button className="order-details-btn" onClick={() => setSelectedOrder(order)}>Details</button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderHistory;
