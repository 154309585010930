import React, { useEffect, useState } from "react";
import "./Partners.css";
import { Container } from "react-bootstrap";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";
import { Title } from "../../../../components";
import axios from "axios";


const Partners = ({url, apiMethod,section }) => {
  const [partners, setPartners] = useState([]); // State to store API data
  const title = section?.data?.section_title;
  const description = section?.data?.section_description;



  const fetchPartners= async () => {
    const apiURL = `${url}${apiMethod}.OurPartners`;
    try {
        const response = await axios.get(apiURL);
        setPartners(response?.data?.result?.partners);
    } catch (error) {
        console.error("Error fetching partners:", error);
    }
};



  useEffect(() => {
    fetchPartners();
  }, []);

  return (
    <div className="partners-div">
      <Container>
      <Title title={title ?? "Our Partners"} para={description ?? "Lorem ipsum dolor sit amet, consectetur adipisicing elit."} />
      </Container>
      <div className="partner-slide">
        <Splide
          options={{
            type: "loop",
            perPage: 10,
            autoplay: true,
            interval: 3000,
            arrows: false,
            pagination: true,
          }}
          aria-label="Image Slider"
        >
          {partners.length > 0 ? (
            partners.map((partner, index) => (
              <SplideSlide key={index}>
                <img src={url + partner.logo} alt={partner.name} />
              </SplideSlide>
            ))
          ) : (
            <p>Loading partners...</p>
          )}
        </Splide>
      </div>
    </div>
  );
};

export default Partners;
