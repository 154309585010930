import React, { useState, useEffect } from "react";
import "./Filters.css";
import { styled } from '@mui/material/styles';
import { PriceRange, CategoriesCheckBox } from "../../../../components";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Tab, Tabs, AccordionDetails, AccordionSummary, Accordion } from '@mui/material';
import arrow from "../../../../asset/images/svg/arrow-accordion.svg"

const Filters = ({
  url,
  onSearch,
  onPriceFilter,
  CategoriesFilter,
  BrandsFilter,
  onCategoryChange,
  onCategoryChange1,
  onChangeBrand,
  isCategory,
  categoryParams=[]
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [checked, setChecked] = useState([true, false]);



  // const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked(Array(5).fill(event.target.checked)); // Select or deselect all
  // };

  // const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked([event.target.checked, checked[1], checked[2], checked[3], checked[4]]);
  // };

  // const handleChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked([checked[0], event.target.checked, checked[2], checked[3], checked[4]]);
  // };

  // const handleChange4 = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked([checked[0], checked[1], event.target.checked, checked[3], checked[4]]);
  // };

  // const handleChange5 = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked([checked[0], checked[1], checked[2], event.target.checked, checked[4]]);
  // };

  // const handleChange6 = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked([checked[0], checked[1], checked[2], checked[3], event.target.checked]);
  // };

  // const children = (
  //   <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
  //     <FormControlLabel
  //       className="checkbox-container"
  //       label={<label className="checkbox-label-products">Sofa</label>}
  //       control={<input type="checkbox" className="custom-checkbox" checked={checked[0]} onChange={handleChange2} />}
  //     />
  //     <FormControlLabel
  //       className="checkbox-container"
  //       label={<label className="checkbox-label-products">Wooden chairs</label>}
  //       control={<input type="checkbox" className="custom-checkbox" checked={checked[1]} onChange={handleChange3} />}
  //     />
  //     <FormControlLabel
  //       className="checkbox-container"
  //       label={<label className="checkbox-label-products">Plastic chairs</label>}
  //       control={<input type="checkbox" className="custom-checkbox" checked={checked[2]} onChange={handleChange4} />}
  //     />
  //     <FormControlLabel
  //       className="checkbox-container"
  //       label={<label className="checkbox-label-products">Lights</label>}
  //       control={<input type="checkbox" className="custom-checkbox" checked={checked[3]} onChange={handleChange5} />}
  //     />
  //     <FormControlLabel
  //       className="checkbox-container"
  //       label={<label className="checkbox-label-products">closet</label>}
  //       control={<input type="checkbox" className="custom-checkbox" checked={checked[4]} onChange={handleChange6} />} 
  //     />
  //   </Box>
  // );

  const [expanded, setExpanded] = React.useState('panel1');
  const handleChangeCheckbox = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  const handleChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    onSearch(searchTerm);
  };

  const handlePriceFilter = (min_price, max_price) => {
    onPriceFilter(min_price[0], min_price[1]); // Passing min and max as separate values
    // onPriceFilter(min_price, max_price);
  };

  const [showAllBrands, setShowAllBrands] = useState(false);
  const brands = BrandsFilter || [];
  const visibleBrands = showAllBrands ? brands : brands.slice(0, 4);

  const toggleShowAllBrands = () => {
    setShowAllBrands(!showAllBrands);
  };

  useEffect(() => {
  }, [categoryParams]);
  
  return (
    <div className="filters">
      <div className="filter">
        <div className="filter-header">
          <div className="filter-title">FILTER BY </div>
          <div className="divider"></div>
          <div className="filter-sub-title">Search</div>

          <div className="search-field">
            <input
              type="text"
              placeholder="Search"
              className="filter-search"
              value={searchTerm}
              onChange={handleChange}
              //  onSubmit={handleChange}
            />
          </div>
        </div>
       

          <div className="brand-filter">
            <div className="filter-sub-title">Brand</div>

            {visibleBrands.map((brand, index) => (
              <div className="check-items" key={index}>
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  value={brand.name}
                  onChange={(event) => onChangeBrand(event)}
                  onClick={(event) => onChangeBrand(event)}
                />{" "}
                <label className="check-items-label">{brand.name}</label>
              </div>
            ))}
            {!showAllBrands && brands.length > 4 && (
              <div className="more-brands" onClick={toggleShowAllBrands}>
                Load More
              </div>
            )}
          </div>
        {!isCategory && <div className="divider"></div>}

        {!isCategory && (
          <PriceRange
              title="Price Range"
              min={0}
              max={10000}
              value={[2000, 8000]} // Initial range
              onValueChange={handlePriceFilter} // Callback
          />
        )}
        {!isCategory && <div className="divider"></div>}
        {!isCategory && (
          <div className="categories">
            <div className="filter-sub-title">Categories</div>

            <Accordion expanded={expanded === 'panel1'} onChange={handleChangeCheckbox('panel1')} className="accordion-products">
              <AccordionSummary 
                className="accordion-products-summary"
                aria-controls="panel1d-content" 
                id="panel1d-header"
                sx={{
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    position: "absolute",
                    left: "0",
                    top: "10px",
                    transform: "rotate(270deg)"
                  },
                  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    transform: "rotate(360deg)",
                   
                  },
                }}
                expandIcon={<img src={arrow} className='products-sidebar-accordion-icon' />}
              >
                {/* <Typography>
                  <FormControlLabel
                    label="Furniture"
                    control={
                      <input 
                        type="checkbox" 
                        className="custom-checkbox"
                        checked={checked[0] && checked[1]}
                        indeterminate={checked[0] !== checked[1]}
                        onChange={handleChange1}
                      />
                    }
                  />
                </Typography> */}
              </AccordionSummary>
              {/* <AccordionDetails>
                <Typography>{children}</Typography>
              </AccordionDetails> */}
            </Accordion>
            
           

            {CategoriesFilter && CategoriesFilter.map((category, index) => (
              <CategoriesCheckBox
                title={category.name}
                subcategories={category.children || []}
                onCategoryChange={onCategoryChange}
                onCategoryChange1={onCategoryChange1}
                selectedCategoryFromCP={categoryParams }
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Filters;
