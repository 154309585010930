import React,{useState} from 'react';
import "./ProductClientReviews.css"
import { Container } from "react-bootstrap";
import { Title, ProductReviewClient } from "../../../../components";
import Rating from '@mui/material/Rating';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "js-cookie";

const ProductClientReviews = ({ url, apiMethod, resultData }) => {
  const [value, setValue] = React.useState(1);
  const [comment, setComment] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = useState('');
  const [reviews, setReviews] = useState(resultData?.details?.reviews || []);

  const AddReview = async () => {
    setError('');
    if (comment.trim() === '') {
      setError('Comment cannot be empty');
      return;
    }

    setLoading(true);
    const apiURL = `${url}${apiMethod}.item_review`;
    try {
      const headers = {
        "Authorization": `token ${Cookies.get("token")}`
      };

      const response = await axios.post(apiURL,
        {

          "web_item": resultData?.details?.data?.name,
          "rating": value,
          "comment": comment,
          "title": ""
        }
        , { headers });
      console.log(response);
      if (response.status == 200) {
        setLoading(false);
        setValue(1);
        setComment(""); 
      }

    } catch (error) {
      setLoading(false);
      toast.error("Sorry, can't add review", {
        position: 'bottom-right',
      });
    }
  };


  return (
    <Container>
      <div className='product-reviews'>
        <Title
          title={"Client Reviews"}
          para=""
        />

        {reviews.length > 0 && (
          <div>
              { 
                reviews.map((review, index) => (
                <ProductReviewClient
                  name={review.customer}
                  para={review.comment}
                  image={
                    review.image
                    ? url + review.image
                    : require("../../../../asset/images/Home/profile-1.png")}
                  time={review.published_on}
                  rate={review.rating}
                />
                ))
              }
           </div>

        )}
        {/* <div className='divider'></div>

        <ProductReviewClient
          name={"Sami Abdul"}
          para={" Lorem ipsum dolor sit amet, consectetur adipiscing elit duis nec fringilla nibh pellentesque finibus Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
          image={require("../../../../asset/images/Home/profile-1.png")}
          time={"7 day ago"}
          rate={4}
        />
        <div className='divider'></div> */}

        <div style={{ marginTop: "40px" }}>
          <div className='reviews-container'>
            <div className='review-title'>
              Add Your Reviews
            </div>
            <div className='d-flex items-center gap-1'>
              <Rating readOnly={false} value={value} size="medium" onChange={(e) => setValue(e.target.value)} />
              {value}
            </div>
          </div>
          <textarea value={comment} onChange={(e)=>setComment(e.target.value)} class="form-control comment" rows="4" placeholder="Your comment" ></textarea>        
          {error && <p className="invalid-feedback">{error}</p>}

          <button style={{ height: "40px" }} disabled={loading}  className='comment-btn' data-aos="fade-right" data-aos-duration="2000" onClick={(e) => AddReview()}>

          {loading == true ? (
            <CircularProgress
              size={24}
            />
          ) :
            <div className='add-comment'>
              Add Comment
            </div>}
          </button>
                  
        </div>
        <ToastContainer position="bottom-right" hideProgressBar="true" type="error" />

      </div>
    </Container>
  );
};

export default ProductClientReviews;