import React from 'react';
import "./OrderDetails.css";
import { OrderCard } from "../../index"
import { Col, Row } from 'react-bootstrap';

const OrderDetails = ({ order, onClose }) => {
  if (!order) return null;

  const orderDetail = order?.items || [];

  return (
      <div className="order-details-container">
        <div className='order-details-title'>Orders Details</div>
        <div className='order-details-content'>
          <div className='order-details-id'><span>Order ID:</span>{order.name}</div>
          <div className={`order-details-status ${order.status.toLowerCase()}`}>{order.status}</div>
        </div>

        <Row>
          <Col lg={9} className='order-card-cotnainer-col'>
            {
              orderDetail.map((item, index) => (
                <OrderCard
                  key={index}
                  title = {item.item_code}
                  currency={order.currency}
                  price={item.rate}
                />
              ))
            }
          </Col>
          <Col lg={3} className='order-billing-container-col'>
            <div className='order-billing-container'>
                <div className='order-billing-title'>Billing Summary</div>
                <div className='order-billing-content'>
                  <div className='order-billing-tax'>
                    <div className='order-billing-tax-title'>Subtotal</div>
                    <div className='order-billing-tax-value'>+ {order.total} {order.currency}</div>
                  </div>
                  <div className='order-billing-tax'>
                    <div className='order-billing-tax-title'>Discount</div>
                    <div className='order-billing-tax-value'>- {order.grand_total - order.before_discount_total} {order.currency}</div>
                  </div>
                  <div className='order-billing-tax'>
                    <div className='order-billing-tax-title'>Tax</div>
                    <div className='order-billing-tax-value'>+ {order.total_taxes_and_charges} {order.currency}</div>
                  </div>
                </div>
                <div className="order-billing-final-total">
                  <div className='order-billing-tax'>
                    <div className='order-billing-tax-title-total'>Tax</div>
                    <div className='order-billing-tax-value'>+ {order.grand_total} {order.currency}</div>
                  </div>
                </div>
            </div>
          </Col>
        </Row>
    
        <button onClick={onClose} className="order-details-close">Close</button>
      </div>
  );
};

export default OrderDetails;
