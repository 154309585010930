import React from 'react';
import "./ClientReviews.css"
import Slider from "react-slick";
import { Container } from "react-bootstrap";
import { Title, ReviewClient } from "../../../../components";


const ClientReviews = ({  url, section }) => {

  const { clients_reviews } = section?.data || [];
  const title = section?.data?.section_title;
  const description = section?.data?.section_description;

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: true,
    responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        }
      ]
  };

  return (
    <Container>
        <Title 
          title={title?? "Client Reviews"}
          para={description??"Lorem ipsum dolor sit amet, consectetur adipisicing elit."}
        />
        <Slider {...settings} className="reviews mt-5">

          {
            clients_reviews.map((item, index) => (
              <ReviewClient 
                key={index}
                name={item.reviewer}
                para={item.review}
                image={url + item.reviewer_profile_image}
                rating={item.rating}
              />
            ))
          }
           
      </Slider>
    </Container>
  );
};

export default ClientReviews;