import React, { useState } from "react";
import "./ProfileTap.css";


const ProfileTap = ({ svg , title , text, onClick, isActive }) => {
  return (
    <div className="" onClick={onClick}>
      <div
        class={`profile-tab profile-tab-flex my-account ${isActive ? "active-tab" : ""}`}
        style={{ position: "relative" }}
        data-tab="profile"
      >
        <div className="profile-tab-svg">
            {svg}
        </div>
        
        <div class="tab-flex">
          <div class="tab-title">{title}</div>
          <div class="tab-text">{text}</div>
        </div>
        <div class="chevron-div">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="10"
            viewBox="0 0 7 14"
            fill="none"
			
          >
            <path
              d="M1.06641 12.2463C1.3584 12.2463 1.59326 12.1447 1.79639 11.9416L6.57617 7.28239C6.84912 7.00945 6.96973 6.74284 6.97607 6.40642C6.97607 6.07634 6.84912 5.79704 6.57617 5.53044L1.80273 0.864914C1.59326 0.668137 1.35205 0.566574 1.06641 0.566574C0.482422 0.566574 0 1.0363 0 1.62029C0 1.91228 0.126953 2.17888 0.342773 2.40105L4.48779 6.41277L0.342773 10.4118C0.120605 10.634 0 10.9006 0 11.1926C0 11.7702 0.482422 12.2463 1.06641 12.2463Z"
              fill="white"
			  className="chevron"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ProfileTap;
