import React, { useEffect, useState } from 'react';
import './App.css';
import { Footer, NavPage, ScrollTop } from "./Layouts/index";
import { Home, LoginPage, VerifyPage, RegisterPage, CheckEmailPage, SuccessPage, ResetPasswordPage, ProductsPage, ProductDetailsPage, CategoriesPage, ShoppingCartPage, CheckoutPage, SubCategoriesPage, AboutUs, Privacy, Portal, Profile } from "./Pages/index";
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Loading } from './components/index';
import axios from "axios";
import Cookies from 'js-cookie';
import faviconLogo from "./asset/images/gold-logo.png"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

function App() {
  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  const [loading, setLoading] = useState(true);
  const url = "https://whatsapptest.inter-netsys.com";
  const apiMethod = "/api/method/geniusapi.www.api";
  const limit = 8;
  const pageUrl = window.location.pathname;
  const lastWordIndex = pageUrl.lastIndexOf("/") + 1;
  let pageName = pageUrl.substr(lastWordIndex);

  const [navbarData, setNavbarData] = useState([]);
  const [footerData, setFooterData] = useState({});
  const [logos, setLogos] = useState({});
  const [resultData, setResultData] = useState({});
  const [page_title, setPageTitle] = useState();
  const [data, setData] = useState([]);
  const [contentData, setContentData] = useState({});
  const [ItemData, setItemData] = useState({});
  const [CategoryData, setCategoryData] = useState({});

  const [innerPageData, setInnerPageData] = useState({});
  const [translationsData, setTranslationsData] = useState({});
  const [seoSettingsData, setSeoSettingsData] = useState({});
  // const [language, setLanguage] = useState("en");
  const [language, setLanguage] = useState(""); // Default language is English
  const [translations, setTranslations] = useState(localStorage.getItem('translations')?JSON.parse(localStorage.getItem('translations')):{});



  // console.log("App.js - language:", language);
  // console.log("App.js - setLanguage:", setLanguage);
  
  const fetchTranslations = async (lang) => {
    // console.log(`Fetching translations for: ${lang}`); // ✅ طباعة اللغة المستخدمة
  
    try {
      const response = await fetch(`${url}/api/method/geniusapi.www.api.get_translation_file?lang=${lang}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      // console.log(`API Called: ${url}/api/method/geniusapi.www.api.get_translation_file?lang=${lang}`); // ✅ طباعة API المستدعى
  
      if (!response.ok) {
        toast.error(`HTTP error! status: ${response.status}`);
        return;
      }
  
      const data = await response.json();
      setTranslations(data);
      localStorage.setItem('translations', JSON.stringify(data));
    } catch (error) {
      console.error(`Error fetching translations: ${error}`);
    }
  };
  
  



  const changeLang = async (lang) => {
    // console.log(`Setting language: ${lang}`); // ✅ طباعة اللغة الجديدة
    
    try {
      const response = await axios.post(`${url}/api/method/geniusapi.www.api.set_language?name=${lang}`);
      // console.log(`API Called: ${url}/api/method/geniusapi.www.api.set_language?name=${lang}`); // ✅ طباعة API المستدعى
  
      if (response.data && response.data.message === "success") {
        Cookies.set('preferred_language', lang);
        setLanguage(lang); // تحديث الحالة
        await fetchTranslations(lang); // تحميل الترجمة مباشرة بعد تغيير اللغة
      } else {
        toast.error('Failed to change language');
      }
    } catch (error) {
      console.error(`Error changing language: ${error}`);
    }
  };
  





useEffect(() => {
  const lang = Cookies.get('preferred_language');  
  if (Cookies.get('preferred_language')==null || language != lang){
    if(language!=null &&  language != ""){
      console.log(language)
      changeLang(language);
    }
    else{
      changeLang("en");
    }
    
  }
  else{
    fetchTranslations(language);
  }


  // if (lang !== language) {
  //     changeLang(language);
  // } else {
  //     fetchTranslations(language); // فقط جلب الترجمة عند عدم الحاجة لتغيير اللغة
  // }
}, [language]);


// useEffect(() => {
//   if (language) {
//     // console.log(`Fetching translations for: ${language}`);
//     fetchTranslations(language);
//   }
// }, [language]);  // ✅ يتم استدعاء fetchTranslations تلقائيًا عند تغيير اللغة

  

  const clearCookies = (clearLang = false) => {
    const cookies = document.cookie.split(";");
    
    
    for (let cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();
      
      // Skip clearing the 'preferred_language' cookie if clearLang is false
      if (name === 'preferred_language' && !clearLang) {
        continue;
      }
      //todo if cookie is expired
      // localStorage.clear();



      // Clear the cookie by setting its expiration date in the past
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
    
  };

  useEffect(() => {
    if (!["", "/", "home"].includes(pageName)) {
      setLoading(false);
      return;
    }

    
    

    const fetchData = async (pageName) => {
      try {
        setLoading(true);
        var apiUrl = `${url}${apiMethod}.content_by_page_name?name=${pageName}`;

        const response = await fetch(apiUrl);
        const data = await response.json();
        const result = data?.result || "";
        const content = data?.result?.content || {};
        const items = data?.result?.items || [];
        setPageTitle(response?.data?.result?.page_title || "");

        if (!pageUrl.includes("category/") && !pageUrl.includes("categories")) {
          const categories = data?.result?.categories || [];
          setCategoryData(categories);
        }

        setResultData(result);
        setContentData(content);

        setItemData(items);
        setLogos(data?.data?.logos || "");
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    if (["", "/", "home"].includes(pageName)) {
      pageName = "home";
    }



  
    // else if ("products".includes(pageName)) {
    //   pageName += `&start=0&limit=${limit}`;
    // }
    // else if (pageUrl.includes("product/")) {
    //   let serviceId = pageUrl.substr(lastWordIndex);
    //   pageName = `product&product_name=${serviceId}`;
    // }
    // else if ("categories".includes(pageName)) {

    //   pageName += `&start=0&limit=${limit}`;
    // }

    // else if (pageUrl.includes("category/")) {
    //   let serviceId = pageUrl.substr(lastWordIndex);
    //   pageName = `category&parent_groups=${serviceId}`;
    // }
    // fetchData(pageName);
  }, []);

  useEffect(() => {
   
    const path = new URL(window.location.href).pathname;
    const pathParts = path.split("/");

  
    if (pathParts.length >= 3 && pathParts[1] === "product" && pathParts[2]) {
      return; 
    }

    fetchWebsiteData(pageName);
  }, [pageName]);

  const fetchWebsiteData = async (pageName) => {
    setLoading(true);
    // const apiKey = Cookies.get('api_key');
    // const apiSecret = Cookies.get('api_secret');
    var headers = { 'Content-Type': 'application/json', };


    try {
      const response = await axios.get(
        `${url}/api/method/geniusapi.genius_api.doctype.pages.pages.page?name=${pageName}`,
        { headers: headers }
      );

      // setData(response?.data?.result?.content?.templates || []);
      setData(
        pageName === "products" 
          ? response?.data?.result
          : response?.data?.result?.content?.templates || []
      );
      // console.log("productsData",response?.data?.result || [])
      setPageTitle(response?.data?.result?.page_title || "");
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const updateFavicon = () => {
      const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'icon';
      link.href = `${logos?.logo_favicon || faviconLogo}`;
      document.head.appendChild(link);
    };

    const updateTitle = () => {
      const mainTitle = `${seoSettingsData?.main_title || ""} ${seoSettingsData?.separator || ""
        } ${innerPageData?.name || ""}`;
      const formattedTitle = mainTitle.trim() || "Genuis";
      if (formattedTitle === undefined) document.title = "Genuis";
      else {
        document.title = formattedTitle;
      }
    };

    updateFavicon();
    updateTitle();
  }, [logos, pageName, seoSettingsData, innerPageData]);

  const isPortalRoute = window.location.pathname === "/portal";

  if (isPortalRoute) {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path='/portal' element={<Portal />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        {loading ? (
          <div>
          <NavPage url={url} translations={translations} language={language}  setLanguage={setLanguage}  />
          <Loading></Loading>
          <Footer translations={translations}/>
          </div>

        ) :
          (
            <>
              <ScrollTop />
              <NavPage url={url} translations={translations} language={language}  setLanguage={setLanguage} />
              <Routes>
                <Route index element={<Home  data= {data} contentData={contentData} innerPageData={innerPageData} itemsList={ItemData} categoryList={CategoryData} url={url} apiMethod={apiMethod} translations={translations} language={language}/>} />
                <Route path="/home" element={<Home data= {data} contentData={contentData} innerPageData={innerPageData} itemsList={ItemData} categoryList={CategoryData} url={url} apiMethod={apiMethod} translations={translations}/>} />
                <Route path='/login' element={<LoginPage url={url} />} />
                <Route path='/register' element={<RegisterPage url={url} />} />
                <Route path='/checkEmail' element={<CheckEmailPage url={url} />} />
                <Route path='/verify' element={<VerifyPage />} />
                <Route path='/success' element={<SuccessPage />} />
                <Route path='/resetPassword' element={<ResetPasswordPage url={url} />} />
                <Route path='/products' element={<ProductsPage data={data} url={url} limit={limit} apiMethod={apiMethod}  />} />
                <Route path="/product/:id" element={<ProductDetailsPage url={url} apiMethod={apiMethod} serviceId={pageUrl.substr(lastWordIndex)} />} />
                <Route path="/categories" element={<CategoriesPage url={url} limit={limit} apiMethod={apiMethod} />} />
                <Route path="/category/:id" element={<SubCategoriesPage url={url} apiMethod={apiMethod} />} />
                <Route path="/shoppingCart" element={<ShoppingCartPage url={url} apiMethod={apiMethod} />} />
                <Route path="/checkout" element={<CheckoutPage url={url} apiMethod={apiMethod} />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/profile' element={<Profile url={url} />} />
              </Routes>
              <Footer translations={translations}/>
            </>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
