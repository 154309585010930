import React from 'react';
import './Paginations.css';
import { Pagination } from 'react-bootstrap';

const Paginations = ({ currentPage, totalPages, onPageChange }) => {
  const renderPaginationItems = () => {
    const paginationItems = [];

    if (totalPages <= 7) {
      for (let page = 1; page <= totalPages; page++) {
        paginationItems.push(
          <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => onPageChange(page)}
          >
            {page}
          </Pagination.Item>
        );
      }
    } else {
      const firstPage = (
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => onPageChange(1)}
        >
          1
        </Pagination.Item>
      );

      const lastPage = (
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => onPageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );

      paginationItems.push(firstPage);

      if (currentPage <= 4) {
        for (let page = 2; page <= 5; page++) {
          paginationItems.push(
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => onPageChange(page)}
            >
              {page}
            </Pagination.Item>
          );
        }
        if (totalPages > 6) {
          paginationItems.push(<Pagination.Ellipsis key="ellipsis-start" />);
        }
        paginationItems.push(lastPage);
      } else if (currentPage >= totalPages - 3) {
        paginationItems.push(<Pagination.Ellipsis key="ellipsis-end" />);
        for (let page = totalPages - 4; page <= totalPages - 1; page++) {
          paginationItems.push(
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => onPageChange(page)}
            >
              {page}
            </Pagination.Item>
          );
        }
        paginationItems.push(lastPage);
      } else {
        paginationItems.push(<Pagination.Ellipsis key="ellipsis-start" />);
        for (let page = currentPage - 2; page <= currentPage + 2; page++) {
          paginationItems.push(
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => onPageChange(page)}
            >
              {page}
            </Pagination.Item>
          );
        }
        paginationItems.push(<Pagination.Ellipsis key="ellipsis-end" />);
        paginationItems.push(lastPage);
      }
    }

    return paginationItems;
  };

  return (
    <Pagination>
      {/* Prev button: Always visible but disabled when on the first page */}
      <Pagination.Prev
        onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {renderPaginationItems()}
      {/* Next button: Always visible but disabled when on the last page */}
      <Pagination.Next
        onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

export default Paginations;
