import React, { useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import "./ProfilePhoneInput.css"

const ProfilePhoneInput = ({ label, disabled, onChange, value }) => {


  return (
    <div 
      className={`profile-input-container ${disabled ? "disabled-input" : ""}`} 
      style={{ padding: "unset" }}
    >
      <label className='profile-input-label'>{label}</label>
      <PhoneInput
        className='profile-input'
        international
        countryCallingCodeEditable={false}
        defaultCountry="SA"
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

export default ProfilePhoneInput
