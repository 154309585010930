import React, { useState, useEffect } from "react";
import "./NewAddress.css"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import {ProfileInput, ProfilePhoneInput} from "../../index";

const containerStyle = {
  width: "100%",
  height: "300px",
};

const defaultCenter = { lat: 25.276987, lng: 55.296249 }; // Default to Dubai

const NewAddress = ({ onClose, addressData, url }) => {
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState(defaultCenter);

  useEffect(() => {
    if (addressData) {
      setFullName(addressData.links[0].link_name || "");
      setAddress(addressData.address_title + " " + addressData.address_line2 || "");
      setPhoneNumber(addressData.phone || "");
    }
  }, [addressData]);

  useEffect(() => {
    if (address) {
      fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 0) {
            setLocation({ lat: parseFloat(data[0].lat), lng: parseFloat(data[0].lon) });
          }
        })
        .catch((error) => console.error("Geocoding error:", error));
    }
  }, [address]);

  const handleSave = async () => {
    const payload = {
      address_title: address,
      address_type: addressData.address_type,
      address_line1: "",
      city: "", 
      country: "",
      address_line2: "",
      state: "", 
      postal_code: "",
      building_number: "", 
      email_id: addressData.modified_by, 
      phone: phoneNumber,
      fax: "", 
      is_primary_address: true,
      is_shipping_address: true
    };
  
    const apiUrl = addressData
      ? `${url}/api/method/geniusapi.www.api.update_address`
      : `${url}/api/method/geniusapi.www.api.add_new_address`;
  
    try {
      const response = await fetch(apiUrl, {
        method: addressData ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log(`Address ${addressData ? "updated" : "added"} successfully:`, data);
      onClose(); // Close the modal after saving
    } catch (error) {
      console.error(`Error ${addressData ? "updating" : "adding"} address:`, error);
    }
  };
  

  return (
    <div className="new-address-container">
      <div className="new-address-form-title">{addressData ? "Edit Address" : "Your New Address"}</div>
      <form className="address-form">
        <div className="mt-3">
          <ProfileInput 
            label="Full Name" 
            placeholder="Full Name" 
            type="text" 
            value={fullName} 
            onChange={(e) => setFullName(e.target.value)} 
          />
        </div>
        <div className="mt-3">
          <ProfileInput 
            label="Phone Number" 
            placeholder="Phone Number" 
            type="text" 
            value={phoneNumber} 
            onChange={(e) => setPhoneNumber(e.target.value)} 
          />
        </div>
        <div className="mt-3">
          <ProfileInput 
            label="Address" 
            placeholder="Enter your address" 
            type="text" 
            value={address} 
            onChange={(e) => setAddress(e.target.value)} 
          />
        </div>
        <div className="address-map-container">
          <LoadScript>
            <GoogleMap mapContainerStyle={containerStyle} center={location} zoom={14} >
              <Marker position={location} />
            </GoogleMap>
          </LoadScript>
        </div>
        <div className="mt-3">
          <ProfileInput 
            label="Additional information about your location (Optional)" 
            placeholder="Enter your address" 
            type="textarea"
            rows={5}  
        />
        </div>
        <div className="addres-btns">
          <button type="button" onClick={onClose} className="address-btn">Close</button>
          <button type="button" onClick={handleSave} className="address-btn">Save Address</button>
        </div>
      </form>
    </div>
  );
};

export default NewAddress;
