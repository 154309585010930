import React from "react";
import "./ProductBanner.css";
import DOMPurify from "dompurify";
import { Container, Row, Col } from "react-bootstrap";
import { Product,Title } from "../../../../components";


const ProductBanner = ({ url, section }) => {
  const { products_gallery } = section.data;
  const  title  = section?.data?.section_title;
  const  description  = section?.data?.section_description;


  return (
    <Container className="products-card">
      <Title
        title={title ?? "Choose what you want"}
        para={
          description ??
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit."
        }
      />
      <Row className="products-gallery-row">
        {products_gallery.map((item, index) => { 


            return(
              <Col lg={6} onClick={() => window.location.href = item.url} style={{ cursor: "pointer" }}>
              <Product
                key={index} // Add a unique key for each product
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item.description),
                    }}
                  />
                } 
                // Render safe HTML
                // para={contentData.category_offer_1_description}
                mainColor={index % 2 === 0} // Alternate true (odd) and false (even)
                image={
                  item.image
                    ? url + item.image
                    : require("../../../../asset/images/Home/slider_3_home.png")
                }
              />
            </Col>
            );

})}
      </Row>
    </Container>
  );
};

export default ProductBanner;
