import React from 'react';
import "./ProfileInput.css";

const ProfileInput = ({ label, value, onChange, placeholder, disabled, type, rows }) => {
  return (
    <div className={`profile-input-container ${disabled ? "disabled-input" : ""}`}>
      <label className='profile-input-label'>{label}</label>
      {type === 'textarea' ? (
        <textarea
          className='profile-input'
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          rows={rows}
          disabled={disabled}
        />
      ) : (
        <input
          className='profile-input'
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default ProfileInput;
