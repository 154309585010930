import React, {useState, useEffect} from 'react'
import "./EditProfile.css"
import { ProfileInput, ProfilePhoneInput } from '../../index'
import { Col, Row } from 'react-bootstrap'




const EditProfile = ({ url, profileData }) => {

    const initialFormData = {
        firstName: profileData?.first_name || '',
        lastName: profileData?.last_name || '',
        email: profileData?.email || '',
        phoneNumber: profileData?.phone || ''
    };
    
    const [formData, setFormData] = useState(initialFormData);
    
    useEffect(() => {
        setFormData(initialFormData);
    }, [profileData]);

    const [hasChanges, setHasChanges] = useState(false);
    
    const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    setHasChanges(true);
    };

    const handleReset = () => {
        setFormData(initialFormData);
        setHasChanges(false);
    };

    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const canSavePassword = currentPassword && newPassword && confirmPassword;

    const handleSavePassword = async () => {
        if (newPassword !== confirmPassword) {
            alert("Passwords do not match!");
            return;
        }

        const formData = new FormData();
        formData.append('old_password', currentPassword);
        formData.append('new_password', newPassword);
        formData.append('confirm_password', confirmPassword);
        formData.append('cmd', 'frappe.core.doctype.user.user.update_password');
        formData.append('email', profileData?.email);

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (data.message === 'Success') {
                alert("Password updated successfully!");
            } else {
                alert("Error updating password: " + data.error);
            }
        } catch (error) {
            console.error("Error:", error);
            alert("An error occurred while updating the password.");
        }
    };

  return (
    <div className='edit-profile-container'>
        <div className='edit-profile-title'>Edit Profile</div>
        <div className='sub-edit-profile-title'>Personal information</div>
        <Row>
            <Col md={3} style={{ paddingLeft: "unset"}} className='profile-inputs-container'>
                <ProfileInput
                    label={"First Name"}  
                    onChange={(e) => handleInputChange("firstName", e.target.value)} 
                    value={formData.firstName} 
                    type={"text"}
                />
            </Col>
            <Col md={3} className='profile-inputs-container'>
                <ProfileInput
                    label={"Last Name"} 
                    onChange={(e) => handleInputChange("lastName", e.target.value)}   
                    value={formData.lastName} 
                    type={"text"}
                />
            </Col>
            <Col md={3} className='profile-inputs-container'>
                <ProfileInput
                    label={"Email"} 
                    onChange={(e) => handleInputChange("email", e.target.value)}   
                    value={formData.email} 
                    type={"email"}
                />
            </Col>
            <Col md={3} style={{ paddingRight: "unset"}} className='profile-inputs-container'>
                <ProfilePhoneInput
                    label={"Phone Number"}  
                    onChange={(phone) => handleInputChange("phoneNumber", phone)}
                    value={formData.phoneNumber}     
                />
            </Col>
        </Row>
        {hasChanges && (
            <div className="profile-btn-container">
                <button onClick={handleReset} className="profile-btn">
                    Reset
                </button>
                <button className="profile-btn">
                    Save
                </button>
            </div>
        )}

        <div className='security-information'>
            <div className='sub-edit-profile-title'>Security Information</div>
            <a href='/resetpassword' className='reset-profile-btn'>Reset Password</a>
            {/* <Row>
                <Col md={4} style={{ paddingLeft: "unset"}} className='profile-inputs-container'>
                    <ProfileInput
                        label={"Current Password "}    
                        placeholder={"Enter your current password to set a new one"}
                        type={"password"}
                        onChange={(e) => setCurrentPassword(e.target.value)}   
                    />
                </Col>
                <Col md={4} className='profile-inputs-container'>
                    <ProfileInput
                        label={"New Password"} 
                        disabled={currentPassword ? false : true}     
                        placeholder={"Please add your new password"} 
                        type={"password"}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </Col>
                <Col md={4} style={{ paddingRight: "unset"}} className='profile-inputs-container'>
                    <ProfileInput
                        label={"Confirm Password"}   
                        disabled={currentPassword ? false : true}  
                        placeholder={"Please confirm your new password"}
                        type={"password"}
                        onChange={(e) => setConfirmPassword(e.target.value)}   
                    />
                </Col>
            </Row>
            {canSavePassword && (
                <div className="profile-btn-container">
                    <button onClick={handleSavePassword} className="profile-btn">
                        Save
                    </button>
                </div>
            )} */}
        </div>
        
      
    </div>
  )
}

export default EditProfile