import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  NavDropdown,
  Nav,
  Form,
  Button,
} from "react-bootstrap";
import "./NavPage.css";
import logo from "../../asset/images/svg/logo-navbar-new.svg";
import man from "../../asset/images/svg/account-icon.svg";
import basket from "../../asset/images/svg/basket2.svg";
import coins from "../../asset/images/svg/coins.svg";
import en from "../../asset/images/svg/lang-icon.svg";
import saudi from "../../asset/images/svg/saudi.svg";
import oman from "../../asset/images/svg/oman.svg";
import logout from "../../asset/images/svg/Logout.svg";
import Percent from '../../asset/images/svg/AnimationPercent.svg';
import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { Menu, MenuItem } from '@mui/material';

const NavPage = ({ url ,translations, language, setLanguage}) => {
  const [scrolled, setScrolled] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("saudi"); 
  const [categories, setCategories] = useState([]);
  const [isProductsPage, setIsProductsPage] = useState(false);

  // console.log("translations",translations)
  // const heroList = contentData.hero_section_image_1 || [];

  // console.log("NavPage.js - language:", language);
  // console.log("NavPage.js - setLanguage:", setLanguage);
  // useEffect(() => {
  //   console.log("Language changed:", language);
  //   console.log("Updated translations:", translations);
  // }, [language, translations]);

  // if (!language || !setLanguage) {
  //   console.error("NavPage: Missing language or setLanguage prop!");
  // }

  const clearCookies = () => {
    const cookies = document.cookie.split(";");

    for (let cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };


  const selectLanguage = (lang) => {
    handleLanguageChange({ target: { value: lang } });
  };

  const toggleLanguage = () => {
    selectLanguage(language === 'en' ? 'ar' : 'en');
  }

  // const toggleLanguage = () => {
  //   setLanguage(language === "en" ? "ar" : "en");
  // };


  


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const fetchCategories = async () => {
    try {
        const response = await axios.get(`${url}./api/method/geniusapi.www.api.categories`);
        setCategories(response.data.result);
    } catch (error) {
        console.error("Error fetching categories:", error);
    }
};

  useEffect(() => {
      fetchCategories();
  }, []);






  const toggleCountry = () => {
    setSelectedCountry((prevCountry) => (prevCountry === "saudi" ? "oman" : "saudi"));
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0 && !scrolled) {
        setScrolled(true);
      } else if (scrollTop === 0 && scrolled) {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  const handleLogout = async () => {
    try {
      const response = await axios.post(url + "/api/method/logout", {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      });

      if (response.status == 200) {
        Cookies.remove("token");
        window.location.reload();
      }
    } catch (error) {}
  };


  useEffect(() => {
    if (window.location.pathname.endsWith("/products")) {
      setIsProductsPage(true);
    } else {
      setIsProductsPage(false);
    }
  }, []);

  return (
    <Navbar
      expand="lg"
      className={`navbar ${isProductsPage ? "nav-products" : ""}`}
    >
      <Container className="nav">
        <Navbar.Brand href="#">
          <a className="navbar-brand" href="/home">
            <img src={logo} />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <div div className="responsive-nav">
            <div className={`up-nav`}>
              <div className="searching">
                <NavDropdown
                  // className={`${scrolled ? "dropdown-scroll" : ""}`}
                  title={translations?.result?.AllCategories ? translations?.result?.AllCategories : "All Categories"}
                  id="basic-nav-dropdown"
                >
                  {categories.map((category, index) => (
                    <NavDropdown.Item key={index} href={`#${category.name}`}>
                      {category.name}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                <input
                  type="text"
                  placeholder={translations?.result?.SearchPlaceHolder ? translations?.result?.SearchPlaceHolder : "Search experts, products or brands"}
                  // className={` ${scrolled ? "searching-input-scroll" : ""}`}
                />
                <button
                  id="search"
                  // className={` ${scrolled ? "btn-search-scroll" : ""}`}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>
              <div className={`settings`}>
              {/* onClick={toggleLanguage} */}
                <div className="settings-content" style={{gap:"10px"}} >
                  <img
                  className="lang-icon"
                    src={en}
                    alt="Change Language"
                  />
                   <span style={{fontSize:"15px"}}>{language === "en" ? "AR" : "EN"}</span>
                </div>
                <div className="settings-seperator"></div>
                {Cookies.get("token") === null ||
                Cookies.get("token") === "" ||
                Cookies.get("token") === undefined ? (
                  <a href="/login" className="settings-content">
                    <img src={man} />
                    <a href="/login">{/* <h1>Sign In</h1> */}</a>
                  </a>
                ) : (
                    <>
                      <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        className="nav-btn"
                      >
                        <img src={logout} />
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem>
                          <a href="/profile" className="settings-content">Profile</a>
                        </MenuItem>
                        <MenuItem>
                          <a onClick={handleLogout} className="settings-content">Log out</a>
                        </MenuItem>
                      </Menu>
                    </>
                    // <div className="settings-content">
                    //   <img src={logout} />
                      
                    // </div>
                  )}
                  <div className="settings-seperator"></div>
                  {/* {Cookies.get("token") !== null &&
                Cookies.get("token") !== "" &&
                Cookies.get("token") != undefined ? ( */}
                <div className="country" onClick={toggleCountry}>
                  <div className="country-content">
                    <img
                      src={selectedCountry === "saudi" ? saudi : oman}
                      alt={
                        selectedCountry === "saudi" ? "Saudi Arabia" : "Oman"
                      }
                    />
                    {/* <h1 style={{color:"#000;"}}>{selectedCountry === "saudi" ? "Saudi Arabia" : "Oman"}</h1> */}
                  </div>
                  {/* <div className="country-content">
                    <img src={coins} />
                    {/* <h1>USD</h1> 
                  </div> */}
                </div>
                <div className="settings-seperator"></div>
                <div className="settings-content">
                  <img
                    src={basket}
                    onClick={() => (window.location.href = "/shoppingCart")}
                  />
                  <div className="basket-number">5</div>
                </div>
                {/* // ) : null} */}
              </div>
            </div>
            <div className={`down-nav`}>
              <div className="links">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link active" href="/home">
                    {translations?.result?.home ? translations?.result?.home : "Home"}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/Kids">
                    {translations?.result?.kids ? translations?.result?.kids : "Kids"}
                      
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link to={"/Décor"} className="nav-link">
                    {translations?.result?.decor ? translations?.result?.decor : "Décor"}
                      
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/Design"} className="nav-link">
                    {translations?.result?.design ? translations?.result?.design : "Design"}
                      
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/categories">
                    {translations?.result?.design ? translations?.result?.design : "Categories"}
                      
                    </a>
                    <div className="hot-status" style={{ top: "-4px" }}>
                      HOT
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link to={"/about"} className="nav-link">
                    {translations?.result?.AboutUs ? translations?.result?.AboutUs : "About Us"}
                      
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                    {translations?.result?.new ? translations?.result?.new : "New"}
                      
                    </a>
                  </li>
                </ul>
                <div className="sale-div">
                  <img src={Percent} alt="Percent Icon" className="spin" />
                  <label
                    className="form-check-label offers-text"
                    htmlFor="specialOffers"
                    style={{ marginRight: "8px" }}
                  >
                    {translations?.result?.sale ? translations?.result?.sale : "SALE"}
                    
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavPage;
