import React from "react";
import "./Home.css";
import {
  Hero,
  Categories,
  Product,
  TrendyProduct,
  SpecialOffer,
  ProductBanner,
  TabBay,
  SplitInFour,
  ClientReviews,
  Partners,
} from "../../Features/Home/index";

export const Home = ({
  data,
  contentData,
  innerPageData,
  itemsList,
  categoryList,
  url,
  apiMethod,
  translations 
}) => {
  const heroSection = data?.find((item) => item.section === "Hero Section");
  const trendyCategorySection = data?.find(
    (item) => item.section === "Trendy Category Section"
  );
  const homeProductsCardsSection = data?.find(
    (item) => item.section === "Home products Cards Section"
  );
  const productSection = data?.find(
    (item) => item.section === "Product Section"
  );
  const categorySection = data?.find(
    (item) => item.section === "Category Section"
  );
  const paySection = data?.find((item) => item.section === "Pay Section");
  const paySplitSection = data?.find(
    (item) => item.section === "Pay Split Section"
  );
  const clientReviewSection = data?.find(
    (item) => item.section === "Client Review Section"
  );
  const specialOffersSection = data?.find(
    (item) => item.section === "Special Offers Section"
  );
  const PartnersSection = data?.find(
    (item) => item.section === "Partners Section"
  );
  const TrendySection = data?.find(
    (item) => item.section === "Trendy Category Section"
  );

  console.log(heroSection);
  return (
    <div className="home">
      <a
        href="https://wa.me/YOUR_PHONE_NUMBER"
        className="whatsapp-float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png"
          alt="WhatsApp"
          className="whatsapp-icon"
        />
      </a>
      {heroSection && (
        <Hero contentData={contentData} url={url} section={heroSection} translations={translations}/>
      )}
      {homeProductsCardsSection && (
        <ProductBanner
          url={url}
          section={homeProductsCardsSection}
        />
      )}

      {specialOffersSection && (
        <SpecialOffer
          itemList={itemsList}
          url={url}
          data={data}
          apiMethod={apiMethod}
          section={specialOffersSection}
        />
      )}

      {trendyCategorySection && (
        <TrendyProduct url={url} section={TrendySection}/>
      )}

      {PartnersSection && (<Partners url={url} apiMethod={apiMethod} section={PartnersSection}></Partners>) }

      {paySection && (
        <TabBay contentData={contentData} url={url} section={paySection} />
      )}
      {paySplitSection && (
        <SplitInFour
          url={url}
          section={paySplitSection}
        />
      )}

      {productSection && (
        <Product
          itemsList={itemsList}
          categoryList={categoryList}
          url={url}
          apiMethod={apiMethod}
          section={productSection}
        />
      )}
      {categorySection && (
        <Categories
          contentData={contentData}
          categoryList={categoryList}
          url={url}
        />
      )}

      {clientReviewSection && (
        <ClientReviews
          url={url}
          section={clientReviewSection}
        />
      )}
    </div>
  );
};

export default Home;
