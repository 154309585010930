import React, { useRef, useState } from "react";
import "./ProfileInfo.css";
import Camera from "../../../../asset/images/profile/svg/Camera.svg";

const ProfileInfo = ({ profileData }) => {
    
  const fileInputRef = useRef(null);
  const [profilePhoto, setProfilePhoto] = useState(null);

  const handlePhotoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Create a URL for the selected file and update the state
      const imageUrl = URL.createObjectURL(file);
      setProfilePhoto(imageUrl);
    }
  };

  const renderProfileContent = () => {
    if (profilePhoto) {
      return <img src={profilePhoto} className="profile-img" alt="Profile" />;
    } else {
      const firstLetter = profileData.first_name && profileData.first_name .trim().length > 0 ? profileData.first_name .charAt(0).toUpperCase() : "";
      return <div className="profile-initial">{firstLetter}</div>;
    }
  };

  return (
    <div className="profile-info">
        <div className="profile-photo-div-parent">
          <div className="profile-photo-div">
            <button
              type="button"
              className="custom-file-upload"
              onClick={handlePhotoClick}
            >
              {renderProfileContent()}
              <div className="camera-profile">
                 <img src={Camera} className="" alt="Camera Icon" />
              </div>
              
            </button>
            <input
              ref={fileInputRef}
              id="select_files"
              type="file"
              accept="image/x-png,image/png,image/jpeg,image/jpg,image/svg+xml"
              onChange={handlePhotoChange}
              style={{ display: "none" }}
            />
          </div>
          <div className="profile-text-info">
            <div className="profile-name">{profileData.first_name}</div>
            <div className="profile-email">{profileData.email}</div>
          </div>
        </div>
    </div>
  );
};

export default ProfileInfo;
