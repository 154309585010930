import React from 'react'
import "./Product.css"
import { Col } from "react-bootstrap"

const Product = ({ title, para, image, mainColor }) => {
    console.log("image",image)
    return (
<div
  className="product-card"
  data-aos="fade-right"
  data-aos-duration="2000"
  style={{ backgroundImage: `url(${image})`}}
>
  <div className="row card-responsive">
    <div className=" product-card-content">
      <div className="product-card-text" id={`${mainColor ? 'main-color' : 'secondary-color'}`}>
        <h1>{title}</h1>
        <image src= { image}></image>

        <p className="product-para" dangerouslySetInnerHTML={{ __html: para }}></p>
        <div className="d-flex">
          <a>Shop Now</a><i className="fa-solid fa-arrow-right-long"></i>
        </div>
      </div>  
    </div>
  </div>
</div>

    )
}

export default Product
