import React, { useState, useEffect } from 'react';
import "./CategoriesSlider.css"
import { ProductsCategory } from '../../../../components'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Col } from 'react-bootstrap';
import '@splidejs/splide/css';
import categoryIcon from "../../../../asset/images/Categories/category-icon.svg";

const CategoriesSlider = ({categories,url, onCategoryChange}) => {
  const [selectedCategories, setSelectedCategories] = useState([]); // Track selected categories


  const colors = ["#A832791C", "#ECC77C1C", "#CF9F471C", "#B06AB31C", "#F098191C","#F7F7F7","#BCA1861C"]; 

  const handleCategorySelect = (categoryName) => {
    if (selectedCategories.includes(categoryName)){
      onCategoryChange(categoryName, false);
    }
    else{
      onCategoryChange(categoryName, true);
    }

    


    setSelectedCategories((prevCategories) => {
      const updatedCategories = prevCategories.includes(categoryName)
        ? prevCategories.filter((category) => category !== categoryName)
        : [...prevCategories, categoryName];
  
      return [...new Set(updatedCategories)];
    });
  };
  
  
  // ✅ Pass updated state properly using useEffect
  useEffect(() => {
    console.log("Updated selected categories:", [...selectedCategories]); 
  }, [selectedCategories]);


  return (
    <div className="categories-slider">
      <Splide
        options={{
          gap: "0px",
          perPage: 12,
          fixedWidth: "80px",
          pagination: false,
          arrows: false,
          breakpoints: {
            1400: {
              perPage: 12,
            },
            1200: {
              perPage: 12,
            },
            992: {
              perPage: 12,
            },
            768: {
              perPage: 12,
            },
            576: {
              perPage: 12,
            },
          },
        }}
      >
        {/* {categories.map((category, index) => (
          <Col xxl={1} xl={2} lg={2} md={2}>
            <SplideSlide>
              <ProductsCategory
                  title={category.name}
                  // image={
                  //   category.image
                  //     ? url + category.image
                  //     : require("../../../../asset/images/Categories/category-icon.svg")
                  // }
                  image= {url + category.image}
                  color= {index%2==0?"#A832791C": "#ECC77C1C"} 
                />
            </SplideSlide>
          </Col>
        ))} */}

        {categories.filter(category => category.parent_item_group === null).map((category, index) => {
          // Determine status manually based on index
          let status = "";
          if (index === 1) {
            status = "HOT"; // Second category
          } else if (index === 3) {
            status = "Trendy"; // Fourth category
          }

          return (
            // <Col xxl={1} xl={1} key={index}>
              <SplideSlide>
                <ProductsCategory
                  title={category.name}
                  image={url + category.image}
                  color={colors[index % colors.length]} // Assign color based on index
                  status={status} // Pass the status
                  category={category.name}
                  selectedCategories={selectedCategories} // Pass selected category
                  handleClick={() => handleCategorySelect(category.name)} 
                              />
              </SplideSlide>
            // </Col>
          );
        })}
      </Splide>
    </div>
  );
}

export default CategoriesSlider
