import React, { useEffect, useState } from "react";
import axios from "axios";
import "./MyProfile.css";
import { EditProfile, ProfileInfo } from "../../index";
import Cookies from "js-cookie";


const MyProfile = ({ url, setLoadingProfile }) => {
  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const headers = {
                "Authorization": `token ${Cookies.get("token")}`
        };
        setLoadingProfile(true);
        const response = await axios.get(`${url}/api/method/geniusapi.www.api.getProfile`, 
          {headers}
        );
        setProfileData(response.data.result);
        if(response) {
          setLoadingProfile(false)
        }
      } catch (err) {
        console.log(err.message);
        setLoadingProfile(false);
      }
    };

    fetchProfile();
  }, []);

  const profileDataList = profileData[0] || {};

  return (
    <div>
      <ProfileInfo profileData={profileDataList} />
      <EditProfile profileData={profileDataList} url={url} />
    </div>
  );
};

export default MyProfile;
