import React from 'react'
import "./Header.css"
import { Container } from 'react-bootstrap'

const Header = ( { image, title, bold, desc }) => {
  return (
    <div className='header'>
        <img className='header-img' src={image} />
        <Container>
            <h1 className='header-title'>{title} <span>{bold}</span></h1>
            <h1 className='header-desc'>{desc}</h1>
        </Container>
    </div>
  )
}

export default Header