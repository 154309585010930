import React from 'react';
import "./TabBay.css"
import logo from "../../../../asset/images/svg/logo-tab.svg"
import tabby from "../../../../asset/images/svg/tabby.svg"
import { Col, Container, Row } from 'react-bootstrap';
import mobile from "../../../../asset/images/svg/mobile.svg"


const TabBay = ({ contentData, url, section }) => {

    const payData = section?.data || {};
  
    return (
      <div className="pay">
        <Container>
          <Row className="pay-responsive">
            <Col lg={6} className="pay-content">
              <h1>{payData.section_title}</h1>
              {/* <p>With <span>Tabby</span>, you can split your purchases into 4 interest-free payments, online or in-store. Shariah-Compliant.</p> */}
              <p dangerouslySetInnerHTML={{ __html: payData.section_description }}/>

              <div className="d-flex">
                <img src=
                    {
                      payData.pay_logo
                      ? url + payData.pay_logo
                      : {tabby}
                  }/>
                
                <img src={logo}/>
            
              </div>
            </Col>
            <Col lg={6}>
              <div className="pay-img" data-aos="fade-up" data-aos-duration="2000">
                <img src=
                    {
                      payData.pay_img
                      ? url + payData.pay_img
                      : {mobile}
                  }/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
  
  export default TabBay;